import TotalCMS from '../totalcms';
import TotalField from './totalfield';
import TotalDispatcher from './dispatcher';
import Identifier from './identifier';
import Checkbox from './checkbox';
import Textarea from './textarea';
import NumberField from './number';
import ColorField from './color';
import DateField from './date';
import PasswordField from './password';
import SelectField from './select';
import MultiSelectField from './multiselect';
import ListField from './list';
import RangeSlider from './range';
import StyledTextField from './styledtext';
import SVGField from './svg';
import ImageField from './image';
import GalleryField from './gallery';
import PropertiesField from './properties';
import CustomPropertiesField from './customProperties';
import SchemaPropertiesField from './schemaProperties';
import JSONField from './json';
import FileField from './file';

// import Deck from './deck';
// import MarkdownField from './markdown';

//-----------------------------------------------
// Total CMS Form constructor
//-----------------------------------------------
export default class TotalForm {

    // Constructors
    constructor(formRef, options = {}) {
        this.form = this.setForm(formRef);
		formRef.totalform = this;

		if (formRef.dataset.disabled !== undefined) {
			return false;
		}

		if (!formRef || !this.form) {
			console.error("form not found");
			return false;
		}

		// Define option defaults
		const defaults = {
			actions : {
				new : {
					action : null,
					link   : null,
				},
				edit : {
					action : null,
					link   : null,
				},
				delete : {
					action : null,
					link   : null,
				},
			}
		};
		this.options = Object.assign({}, defaults, options);

		if (this.form.dataset.newAction) {
			this.options.actions.new = JSON.parse(this.form.dataset.newAction);
		}
		if (this.form.dataset.editAction) {
			this.options.actions.edit = JSON.parse(this.form.dataset.editAction);
		}
		if (this.form.dataset.deleteAction) {
			this.options.actions.delete = JSON.parse(this.form.dataset.deleteAction);
		}
		this.delayActions = 2500;

		this.api = new TotalCMS({
			url: this.form.dataset.api,
		});
		this.route      = this.form.dataset.route;
		this.method     = this.form.dataset.method||"PUT";
		this.id         = this.form.dataset.id||"";
		this.collection = this.form.dataset.collection;
		this.schema     = this.form.dataset.schema;
		this.states     = ["unsaved","success","error","processing"];
		this.state      = null;

		// Check if the form is a collection, schema or object
		this.type = this.form.dataset.form || "object";

		// Check if the form has an ID set
		// This is done before fields are created in case the ID is autogenerated
		const formIDSet = (typeof this.form.dataset.id !== "undefined" && this.form.dataset.id.length > 0);

		this.fields   = []; // set to empty array to avoid issues with ID autogen field
		this.processFields();
		this.droplets = this.fields.filter(field => field.isDroplet());

		// If an ID is set, we are in edit mode
		if (formIDSet) {
			this.editMode();
		}

		this.dispathcer = new TotalDispatcher(this.form);

        this.saveListener();
        this.registerButtons();

		if (this.form.classList.contains("autosave")) {
			this.autosave = true;
		}

		// Don't run the beforeunload event when inside iframes
		if (window === window.top) {
			window.onbeforeunload = e => {
				if (this.isUnsaved()) {
					e.preventDefault();
					const dialogText = "There are unsaved changes";
					e.returnValue = dialogText;
					return dialogText;
				}
			};
		}
    }

    //-------------------------
    // Utility Methods
    //-------------------------

	isObjectForm() {
		return this.type === "object";
	}
	isCollectionForm() {
		return this.type === "collection";
	}
	isSchemaForm() {
		return this.type === "schema";
	}
    // Check to see if the object is a HTML node.
    isDomNode(node){
        return node && typeof node === "object" && "nodeType" in node && node.nodeType === 1;
    }
    // Set the form via a DOM element or selector string
    setForm(formRef) {
        switch(typeof formRef) {
            case "string":
                return document.getElementById(formRef);
            case "object":
                if (this.isDomNode(formRef)){
                    return formRef;
                }
                break;
        }
        return null;
    }

	setId(id) {
		this.id = id;
		this.form.dataset.id = id;
	}

    //-------------------------
    // Init Form
    //-------------------------
    processFields() {
		const fields = Array.from(this.form.getElementsByClassName("form-field"));
		const fieldObjects = [];
        fields.forEach(field => {
			// skip if field is already processed
			if (field.totalfield) {
				fieldObjects.push(field.totalfield);
				return;
			}

			const object = this.generateFieldObject(field);
            if (object === null) return; // if the object is not set, skip it
            fieldObjects.push(object);

			// Mark as dirty
            field.addEventListener("field-change", e => {
				// Ignore change events when form is processing
				// If a field is in focus on submit, a change event is triggered
				if (this.isProcessing()) return;
				this.unsaved();
				if (this.autosave) this.save();
			});
            field.addEventListener("field-error", e => this.error(e.detail.error));
        });
        this.fields = fieldObjects;
    }

    registerButtons() {
		// Save button action is handled by the TotalFormManager

		const deleteButtons = Array.from(this.form.querySelectorAll("button.cms-delete,a.cms-delete,.cms-delete a,.cms-delete button"));
        deleteButtons.forEach(button => {
            button.addEventListener("click", event => {
                event.preventDefault();
				this.delete();
            });
        });
    }

    generateFieldObject(field) {
        const options = JSON.parse(field.dataset.options||"{}");
        options.form = this;

        switch (field.dataset.type) {
			case "id":
			case "slug":
                return new Identifier(field, options);

			case "text":
			case "time":
            case "url":
			case "hidden":
			case "email":
			case "phone":
				return new TotalField(field, options);

			case "textarea":
				return new Textarea(field, options);

            case "checkbox":
            case "toggle":
                return new Checkbox(field, options);

            case "number":
                return new NumberField(field, options);

            case "color":
                return new ColorField(field, options);

            case "date":
			case "datetime":
                return new DateField(field, options);

            case "select":
                return new SelectField(field, options);

            case "multiselect":
                return new MultiSelectField(field, options);

            case "list":
                return new ListField(field, options);

			case "password":
				return new PasswordField(field, options);

            case "range":
                return new RangeSlider(field, options);

			case "styledtext":
                return new StyledTextField(field, options);

            case "svg":
                return new SVGField(field, options);

			case "image":
				return new ImageField(field,options);

			case "gallery":
				return new GalleryField(field,options);

			case "json":
				return new JSONField(field,options);

			case "file":
				return new FileField(field,options);

			// case "depot":
			//     return this.initArrayDroplet(field,options);

			// case "radio":
			// 	return new RadioField(field, options);

			// case "deck":
            //     return new Deck(field, options);

			// case "markdown":
            //     return new MarkdownField(field, options);

			case "properties":
				return new PropertiesField(field,options);

			case "customProperties":
				return new CustomPropertiesField(field,options);

			case "schemaProperties":
				return new SchemaPropertiesField(field,options);

            default:
                console.warn("Unknown field",field);
				return new TotalField(field, options);
        }
    }

    //-------------------------
    // Submit functions
    //-------------------------
    saveListener() {
		// Prevent the default form submission
		if (this.method.toUpperCase() !== "GET") {
			this.form.addEventListener("submit", event => event.preventDefault());
		}
    }

	validate() {
		if (this.id.length === 0) return false;

		this.fields.forEach(field => field.validate());

		if (this.form.checkValidity()) {
			return true;
		}
		// If the form is invalid, display the custom validation error messages
		this.form.reportValidity();
		return false;
	}

	save() {
		if (!this.validate()) return;
        this.processing();
        this.api.postAPI(this.route, this.generateData(), this.method)
            .then(response => this.afterSave(response))
            .catch(error => this.error(error));
    }

    delete() {
        // Only delete if editing object
        if (!this.isEditMode()) return;

        if (window.confirm("Are you sure that you want to delete this? This cannot be undone.")) {
            this.processing();

            // After delete, redirect to current page without any URL parameters
            this.options.editAction = "redirect";
            this.options.editLink   = location.origin + location.pathname;

			let deleteAPI = `/collections/${this.collection}/${this.id}`;

			if (this.isSchemaForm()) {
				deleteAPI = `/schemas/${this.id}`;
			}
			if (this.isCollectionForm()) {
				deleteAPI = `/collections/${this.id}`;
			}

            this.api.postAPI(deleteAPI, {}, "DELETE")
                .then(response => this.runDeleteAction(response))
                .catch(error => this.error(error));
        }
    }

    afterSave(response) {
        if (!response) return;

        if (this.droplets.length > 0) {
            return this.saveDroplets(() => this.afterSaveAction(response));
        }
		this.afterSaveAction(response);
    }

    afterSaveAction(response) {
		const runEditAction = this.isEditMode();
        this.success();
        const waitUntilSaved = () => {
            // wait until all saving states have completed
            if (this.isSuccess()) {
				// Mark all fields as saved
				this.fields.forEach(field => field.saved());
                // run actions
                return runEditAction ? this.runEditAction() : this.runNewAction();
            }
            // Check again
            window.setTimeout(waitUntilSaved,250);
        };
        waitUntilSaved();
    }

    runAction(action) {
        switch (action.action) {
            case "refresh":
                location.reload(true);
                break;
            case "redirect-object":
				const link = decodeURI(action.link);
				if (link.match("{id}"))  {
					document.location = link.replace("{id}",this.id);
				} else {
					document.location = link+this.id;
				}
                break;
            case "redirect":
                document.location = action.link;
                break;
			case "ajax":
				fetch(action.link, {
					method : 'POST',
					mode   : 'cors',
					body   : JSON.stringify(this.generateData()),
				 });
				break;
			case "back":
				const referrerUrl = new URL(document.referrer);
				if (window.history.length > 1 && document.referrer && referrerUrl.hostname === window.location.hostname) {
					document.location = document.referrer;
				}
				break;
        }
    }

    runNewAction() {
		setTimeout(() => this.runAction(this.options.actions.new), this.delayActions);
    }

    runEditAction() {
		setTimeout(() => this.runAction(this.options.actions.edit), this.delayActions);
    }

	runDeleteAction() {
		setTimeout(() => this.runAction(this.options.actions.delete), 250);
    }

    //-------------------------
    // Form States
    //-------------------------
	isEditMode() {
        return ("PUT" === this.method.toUpperCase() && this.form.classList.contains("edit-mode"));
    }

    editMode() {
		if (this.isEditMode()) {
			return;
		}

		// Set the method to PUT for editing existing objects
		this.method = "PUT";
		this.form.dataset.method = this.method;
		this.form.classList.add("edit-mode");

		// The ID cannot be changed in edit form
		const idField = this.fields.filter(field => field.property === "id").shift();
		// if ID field is hidden, these may not exist
		if (idField.disable) {
			idField.disable();
			idField.lock();
		}

		// Update the API to the edit endpoint
		this.route = `${this.route}/${this.id}`;
		this.form.dataset.route = this.route;

		// Update the droplets to autoupload
		this.droplets.forEach(field => field.droplet.autoProcessQueue());
    }

	uploadComplete() {
		if (this.isUnsaved()) return;
		this.success();
	}

	changeState(newState, details = {}) {
		this.state = newState;
		if (newState) {
			this.form.classList.add(newState);
			this.form.dispatchEvent(new CustomEvent(newState, {detail: details}));
		}
		// filer the newState and remove all others
		const remove = this.states.filter(e => e !== newState);
		this.form.classList.remove(...remove);
	}

	clear() {
		this.changeState(null);
	}

	unsaved() {
		this.changeState("unsaved");
	}

	isUnsaved() {
		let unsaved = false;
		this.fields.forEach(field => { if (field.isUnsaved()) unsaved = true; });
        return unsaved;
    }

	processing() {
		this.changeState("processing");
    }

	isProcessing() {
        return this.state === "processing";
    }

    error(error) {
		this.changeState("error", {error:error});
        console.error("Form Error", error);
    }

	isError() {
		return this.state === "error";
	}

    success() {
		this.editMode();
		this.changeState("success");
		this.fields.forEach(field => field.saved());
    }

	isSuccess() {
		return this.state === "success";
	}


    //-------------------------
    // Droplet Interactions
    //-------------------------

    // We only want to process the droplet queue after the inital
    // post request to create the object has been saved
    saveDroplets(callback) {

        let dropletCount = this.droplets.length;

        const dropletComplete = (callback) => {
            // When there are multiple droplets, we need to ensure that the callback
            // is only processed once for the entire form submission.
            // Example: if there are 3 droplets, run after the 3rd time this has been triggered
            dropletCount--;
            if (dropletCount === 0) {
                if (typeof callback === "function") callback();
            }
        };

		this.droplets.forEach(field => {
			field.updateAPIUrl(); // update the droplet API URL
			const droplet = field.droplet;
            if (droplet.isComplete()) {
                dropletComplete(callback);
                return;
            }
            droplet.onQueueComplete(() => dropletComplete(callback));
            droplet.processQueue();
		});
    }

    //-------------------------
    // Generating Form Data
    //-------------------------

    generateData() {
        const data = {};
		this.fields.forEach(field => {
			if (field.isSubField()) return; // skip subfields
			data[field.property] = field.getValue();
		});
        return data;
    }
}
